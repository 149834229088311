@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

:root {
    --main-color: #027581;
    --main-gradient: linear-gradient(to right,  #f7506d, #f98046);
    --color-dark: #1D2231;
    --text-grey: #8390A2;
    --front-bg: #f2f1f5;
    --front-color: #f7506d;
}


* {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style-type: none;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow: auto;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%
}


.front-header {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    padding: 20px 10px 10px 10px;
    background: linear-gradient(0deg, rgb(36, 36, 210) 0%, rgb(33, 33, 156) 100%);
    width: 200px;
    height: 100%;
    z-index: 100;
    box-shadow: 3px 0px 3px -1px #000000;
}

.backImageContainer {
  background-color: #FFFFFF;
  background-image: url('img/back.png');
  background-size: contain;
  position: fixed;
  background-repeat: no-repeat;
  top: 0px;
  left: calc(100% - 500px);
  height: 100%;
  min-height: 400px;
  width: 100%
}

.stepHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 0.2px solid #000000
}

.steps {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 0.5px solid #2424d2;
  border-radius: 15px;
  background-color: #2424d2;
  margin-right: 5px;
}

.stepActive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 0.5px solid #2424d2;
  border-radius: 15px;
  background-color: #FFFFFF;
  margin-right: 5px;
}

.packageContainer {
  margin-top: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0.5px dashed #000000;
  border-radius: 10px;
  width: 100%;
  height: 40;
  justify-content: flex-start;
  overflow: auto;
  background-color: #FFFFFF
}

.packageContainer:hover {
  background: linear-gradient(90deg, rgb(255, 255, 255) 20%, rgb(242, 245, 252) 100%)
}

.packageContainer_selected {
  margin-top: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px dashed var(--main-color);
  border-radius: 10px;
  width: 100%;
  height: 40;
  justify-content: flex-start;
  overflow: auto;
  background: linear-gradient(90deg, rgb(255, 255, 255) 20%, rgb(242, 245, 252) 100%)
}

.packageTitle {
  display: flex;
  flex-direction: column;
  margin-left: 15px
}

.packageHeader {
  font-weight: bold;
}

.packageDescription {
  font-weight: lighter;
  font-size: 12px
}

.price {
  font-weight: bold;
  font-size: 14;
  color: red;
  position: absolute;
  right: 40px
}

.mainArea {
  position: fixed;
  left: 50px;
  top: 80px;
  right: 20px;
  padding: 1.3rem 1.8rem;
  background: #ffffff ;
  border-radius: 8px;
  align-self: center;
  height: calc(100% - 120px);
  width: calc(100% - 140px);
  min-height: inherit;
  z-index: 100;
  box-shadow: 3px 1px 7px 0px #000000;
  overflow: auto;
  overflow-y: scroll
}

.yellowBox {
  width: calc(100% - 140px);
  right: 20px;
  height: 65px;
  background-color: #fcfbed;
  border: 0.5px dotted #000;
  border-radius: 10px;
  position: fixed;
  padding: 5px;
  top: 5px;
  left: 50px;
  margin-left: 160px;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoBox {
  background-color: #fcfbed;
  border: 0.5px dotted #000;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.yellowBoxText {
  font-size: 2.1vh;
}

.addressBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px
}

.addressInput {
  display: flex;
  flex-direction: column;
  width: calc(100% - 70px)
}

.apartmentInput {
  display: flex;
  flex-direction: column;
  width: 60px;
}

.boldText {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 3px
}

.phoneRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px
}

.buttonRow {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  align-items: center;
  margin-top: 25px
}

.error {
  display: flex;
  align-self: flex-end;
  margin-top: 25px
}

.buttonNext {
  width: 100px;
  height: 30px;
  background-color: #2424d2;
  color: #FFFFFF;
  border-radius: 10px;
  border: 0px;
  display:inline-block;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonNext:hover {
  background-color: #5959de;
}

.buttonNext:active {
  margin-top: 3px
}

.buttonBack {
  width: 100px;
  height: 30px;
  background-color: #e53051;
  color: #FFFFFF;
  border-radius: 10px;
  border: 0px;
  display:inline-block;
  outline: none;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonBack:hover {
  background-color: #e3546e;
}

.buttonBack:active {
  margin-top: 3px
}

.confirmationBox {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px
}

.confimationRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 100px
}

.pickup {
  display: flex;
  flex-direction: column;
}

.dropoff {
  display: flex;
  flex-direction: column;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}



.formArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px
}

.bottomContacts {
  display: flex;
  position: fixed;
  flex-direction: column;
  bottom: 7px;
  left: 210px;
  font-size: 0.8vw;
}

.socialMedia {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 2px;
  left: 50px;
  z-index: 2000
}

.socialImage {
  height: 30px;
  width: 30px;
  margin-right: 20px;
}

.paymentArea {
  width: calc(100% - 70px)
}

@media only screen and (min-width: 320px) {
  .backImageContainer {
    display: none;
  }
  .mainArea {
    width: calc(80%)
  }
  .yellowBox {
    width: calc(70% - 125px)
  }
  .confimationRow {
    margin-right: 10px
  }

  .bottomContacts {
    font-size: 1.4vw
  }
  .stepHeading {
    color: #2424d2;
    font-size: 3vw
  }
  .yellowBoxText {
    font-size: 1.5vh;
  }
  .boldText {
    font-size: 1.4vh
  }
  .packageDescription {
    font-size: 1.5vh
  }
  .price {
    font-size: 1.8vh
  }
  .cardContainer {
    width: 100%
  }

  .paymentArea {
    width: 100%
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .backImageContainer {
    display: none;
  }
  .mainArea {
    width: calc(70%)
  }
  .yellowBox {
    width: calc(70% - 155px)
  }
  .confimationRow {
    margin-right: 10px
  }

  .bottomContacts {
    font-size: 1.4vw
  }
  .stepHeading {
    color: #2424d2;
    font-size: 2vw
  }
  .price {
    font-size: 2.8vh
  }
  .cardContainer {
    width: 290px
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .mainArea {
    width: calc(50%)
  }
  .yellowBox {
    width: calc(50% - 155px)
  }
  .backImageContainer {
    display: flex;
  }

  .bottomContacts {
    font-size: 0.8vw
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .mainArea {
    width: calc(50%)
  }
  .yellowBox {
    width: calc(50% - 155px)
  }
  .backImageContainer {
    display: flex;
  }

  .bottomContacts {
    font-size: 0.8vw
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mainArea {
    width: calc(50%)
  }
  .yellowBox {
    width: calc(50% - 155px)
  }

  .bottomContacts {
    font-size: 0.8vw
  }
}
